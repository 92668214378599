<template>
    <div class="video-container">
  <iframe 
    width="560" 
    height="315" 
    src="https://www.youtube.com/embed/_ueLL1GlPqQ?si=89QCKNGTjM_RJ4vp" 
    title="YouTube video player" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen>
  </iframe>
</div>
</template>

<style scoped>
.video-container {
  width: 60%; /* Video genişliği %60 olacak şekilde ayarlandı */
  margin: 20px auto;
  text-align: center; /* Ortalamak için */
}

.video-container iframe {
  width: 100%; /* Video genişliğini kapsayan alan */
  height: 512px; /* Video yüksekliği */
}
</style>