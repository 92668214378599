<template>
  <nav class="navbar">
    <div class="logo">
      <h1>SMTTECH</h1>
    </div>
    <ul class="nav-links">
      <li><a href="#anasayfa">Anasayfa</a></li>
      <li><a href="#hizmetlerimiz">Hizmetlerimiz</a></li>
      <li><a href="#iletisim">İletişim</a></li>
    </ul>
  </nav>
</template>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #333;
  padding: 10px 20px;
  width: 70%;
  margin: 0 auto;
}

.logo h1 {
  color: #fff;
  margin: 0;
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
}

.nav-links li {
  margin: 0 15px;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.nav-links a:hover {
  color: #ffcc00;
}
</style>