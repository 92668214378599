<template>
    <div class="image-gallery">
  <img src="../assets/1.jpg" alt="Image 1">
  <img src="../assets/2.jpg" alt="Image 2">
  <img src="../assets/3.jpg" alt="Image 3">
</div>
</template>

<style scoped>
.image-gallery {
  width: 60%; /* Galeriyi %60 genişlikte tut */
  margin: 20px auto; /* Ortala */
  display: flex;
  justify-content: space-between; /* Resimler arasında boşluk bırak */
}

.image-gallery img {
  width: 30%; /* Her bir resmin genişliğini %30 yap */
  height: auto; /* Yükseklik oranını koru */
  border-radius: 5px; /* Kenarları yuvarla (isteğe bağlı) */
}
</style>